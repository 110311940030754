<template>
  <div class="setting_plan_component">
    <CancelRecurringPlanDialog />
    <CancelPlanDialog />
    <div v-if="isSubscriptionsLoader">
      <beat-loader class="mt-5" :size="'14px'" :color="'#6173ff'"></beat-loader>
    </div>
    <section v-else class="section billing_section">
      <div class="">
        <div class="billing-section-row">
          <div class="grey-border-right billing-left-section pr-4">
            <div class="row">
              <CstBanner
                v-if="hasPermission('white_label') && !isWhiteLabelUnlocked && featureFlag()"
                class="mr-1"
              >
                <template v-slot:banner-content>
                  <p class="text-white">
                    Unlock your brand’s potential—go White Label and customize
                    your platform today! [<span
                      class="text-primary-cs-500 font-semibold hover:text-primary-cs-600 cursor-pointer"
                      @click="$bvModal.show('whitelabel-upgrade-modal')"
                      >{{ ' Purchase Now ' }}</span
                    >]
                  </p>
                </template>
              </CstBanner>

              <b-modal
                id="whitelabel-upgrade-modal"
                hide-header
                hide-footer
                body-class="!p-0 !m-0 !w-[645px] lg:!w-[950px]"
                content-class="!w-[645px] lg:!w-[950px]"
                dialog-class="flex w-screen h-[90vh] justify-center items-center"
              >
                <WhiteLabelUpgradeModal
                  show-close-button
                  @close="$bvModal.hide('whitelabel-upgrade-modal')"
                />
              </b-modal>
              <!-- add on pricing plan -->
              <div class="col-lg-12 top_heading">
                <h2>Plan Details</h2>
              </div>

              <div
                v-if="
                  superAdmin.state === 'due_date' ||
                  superAdmin.state === 'overdue'
                "
                class="col-lg-12"
              >
                <div class="row billing-problem-notification mb-4">
                  <div class="col-lg-8">
                    <h2>We are having a billing problem in your account.</h2>
                    <p
                      >Please enter a new payment method or check with your
                      payment provider for details on why the transaction
                      failed.</p
                    >
                    <a class="upgrade-link" href="">Upgrade Billing details</a>
                  </div>

                  <div class="col-lg-4">
                    <img
                      class="w-100 cat-vector"
                      src="@assets/img/settings/pricing/cat-vector.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <!-- subscription boxes -->
              <div class="active-subscription-boxes">
                <div
                  class="active-subscription-box current-plan-box"
                  :class="{
                    'only-single-box':
                      !isTrialPlan() && !mainSubscription?.next_payment,
                  }"
                >
                  <div class="current-plan-box-image"> </div>
                  <h3 class="active-subscription-heading">Current plan</h3>
                  <h4 class="active-subscription-plan width-70 font-weight-500">
                    {{ getPlan.subscription.display_name }}</h4
                  >
                  <div class="active-subscription-buttons">
                    <!-- upgrade plan button
                                        NOTE: For the lifetime customers, we are showing increase limits, but for the
                                        other plans, we will be showing the upgrade plan option.
                                        -->

                    <template v-if="isPlanUpgradeable">
                      <b-button
                        variant="studio-theme"
                        @click="$bvModal.show('upgrade-plan-dialog')"
                      >
                        Upgrade Subscription
                      </b-button>
                    </template>
                    <template v-else>
                      <b-button
                        variant="studio-theme"
                        @click="$bvModal.show('increase-limits-dialog')"
                      >
                        Upgrade Limits
                      </b-button>
                    </template>

                    <!-- change trial plan button -->

                    <b-button
                      v-if="isTrialPlan()"
                      variant="studio-theme-transparent"
                      class="ml-3"
                      @click="$bvModal.show('change-trial-plan-dialog')"
                      >Change Trial Plan
                    </b-button>

                    <!-- pause subscription page -->
                    <template
                      v-if="
                        !isTrialPlan() &&
                        !isOnLifetimePlan() &&
                        isActiveUserSuperAdmin()
                      "
                    >
                      <b-button
                        v-if="
                          mainSubscription.next_payment &&
                          !mainSubscription.paused_at
                        "
                        variant="studio-theme-transparent"
                        class="ml-3"
                        :disabled="isPauseSubscriptionLoader"
                        @click="pauseSubscriptionAction"
                        >Pause Subscription
                        <clip-loader
                          v-if="isPauseSubscriptionLoader"
                          :color="'#6173ff'"
                          class="ml-2"
                          :size="'12px'"
                        ></clip-loader>
                      </b-button>
                      <!-- resume billing button -->
                      <b-button
                        v-if="mainSubscription.paused_at"
                        variant="studio-theme-transparent"
                        :disabled="isResumeSubscriptionLoader"
                        class="ml-3"
                        @click="resumeSubscriptionAction"
                        >Resume Subscription
                        <clip-loader
                          v-if="isResumeSubscriptionLoader"
                          :color="'#6173ff'"
                          class="ml-2"
                          :size="'12px'"
                        ></clip-loader>
                      </b-button>
                    </template>
                  </div>
                  <div
                    v-if="mainSubscription.paused_from"
                    class="active-subscription-note"
                  >
                    Your subscription will be paused from
                    {{ getShortDate(mainSubscription.paused_from) }}
                  </div>
                </div>
                <div
                  v-if="isTrialPlan() || mainSubscription.next_payment"
                  class="active-subscription-box"
                >
                  <img
                    class="subscription-next-payment-icon"
                    src="@assets/img/settings/pricing/subscription-next-payment.svg"
                    alt=""
                  />
                  <h3 class="active-subscription-heading">
                    <template v-if="isTrialPlan()"> Trial days left </template>
                    <template v-else-if="mainSubscription.next_payment">
                      Renewal Amount
                    </template>
                  </h3>
                  <h2 class="active-subscription-price">
                    <template v-if="isTrialPlan()">
                      <strong>{{ getPlan.trial_overs_in }}</strong>
                    </template>
                    <template v-else-if="mainSubscription.next_payment">
                      {{
                        findSymbolFromCurrencyCode(
                          mainSubscription.next_payment.currency
                        )
                      }}{{ mainSubscription.next_payment.amount }}
                    </template>
                  </h2>
                  <h4 class="active-subscription-plan">
                    <template v-if="isTrialPlan()">
                      You are on a trial plan.
                    </template>
                    <template v-else-if="mainSubscription.next_payment">
                      On
                      {{
                        getNextPaymentDate(mainSubscription.next_payment.date)
                      }}
                    </template>
                  </h4>

                  <template v-if="isActiveUserSuperAdmin()">
                    <div
                      v-if="isTrialPlan()"
                      class="active-subscription-buttons"
                    >
                      <b-button
                        variant="studio-theme-transparent-red"
                        @click="$bvModal.show('cancelPlanModal')"
                        >Close Account
                      </b-button>
                    </div>
                    <div v-else class="active-subscription-buttons">
                      <b-button
                        variant="studio-theme-transparent-red"
                        @click="cancelSubscription(mainSubscription)"
                        >Cancel Subscription
                      </b-button>
                    </div>
                  </template>
                </div>
              </div>

              <!-- add on subscriptions section -->

              <template v-if="allSubscriptions && allSubscriptions.length">
                <div class="col-lg-12 top_heading">
                  <h2> Your Subscriptions</h2>
                </div>
                <div class="col-lg-12">
                  <div class="row subs-add-table">
                    <div class="col-lg-12">
                      <table>
                        <thead>
                          <tr>
                            <th>Created Date</th>

                            <th>Subscription Plan</th>
                            <!--                            <th>Last Payment</th>-->
                            <th>Next Payment</th>
                            <th class="text-nowrap">Status</th>
                            <th class="text-center">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <template v-if="isTransactionHistoryLoaded">
                            <tr class="spacer"> &nbsp;</tr>
                            <tr class="border">
                              <td colspan="6">
                                <clip-loader
                                  :size="'14px'"
                                  :color="'#6173ff'"
                                ></clip-loader>
                              </td>
                            </tr>
                          </template>
                          <template
                            v-if="
                              allSubscriptions.length === 0 &&
                              !isTransactionHistoryLoaded
                            "
                          >
                            <tr class="spacer"> &nbsp;</tr>
                            <tr class="border mt-3">
                              <td colspan="6" class="make-text-center"
                                >You have no active subscriptions
                              </td>
                            </tr>
                          </template>
                          <template
                            v-for="(subscription, index) in allSubscriptions"
                            :key="index"
                          >
                            <tr class="spacer"> &nbsp;</tr>

                            <tr class="border">
                              <td>{{
                                getShortDate(
                                  subscription.subscription_created_at
                                )
                              }}</td>
                              <td>{{ subscription.plan_name }}</td>
                              <td
                                v-if="
                                  subscription.next_bill_date &&
                                  (subscription.status === 'active' ||
                                    subscription.status === 'past_due')
                                "
                              >
                                <strong class="price-blue"
                                  >{{
                                    findSymbolFromCurrencyCode(
                                      subscription.currency || 'USD'
                                    )
                                  }}{{
                                    subscription.next_payment_amount ??
                                    subscription.unit_price ??
                                    '-'
                                  }}</strong
                                >
                                on
                                {{ getShortDate(subscription.next_bill_date) }}
                              </td>
                              <td v-else> --- </td>

                              <td
                                :class="[
                                  'text-nowrap',
                                  {
                                    'status-active':
                                      subscription.status === 'active',
                                    'status-paused':
                                      subscription.status === 'paused',
                                    'status-due':
                                      subscription.status === 'past_due' ||
                                      subscription.status === 'cancelled' ||
                                      subscription.status === 'canceled' ||
                                      subscription.status === 'deleted',
                                  },
                                ]"
                                >{{
                                  subscription.status === 'past_due'
                                    ? 'Past Due'
                                    : subscription.status
                                }}
                              </td>
                              <td>
                                <div class="td-actions-button">
                                  <template
                                    v-if="subscription.status !== 'deleted'"
                                  >
                                    <div
                                      v-if="
                                        subscription.passthrough &&
                                        subscription.passthrough.match(
                                          'workspaces'
                                        )
                                      "
                                      v-tooltip="'View Limits'"
                                      class="button-with-text mr-2"
                                      @click="viewAddonsLimits(subscription)"
                                    >
                                      <b-button
                                        variant="studio-theme-grey-icon"
                                      >
                                        <i class="fas fa-eye"></i>
                                      </b-button>
                                      <span> Limits </span>
                                    </div>
                                    <div
                                      v-tooltip="'View card information'"
                                      class="button-with-text mr-2"
                                    >
                                      <b-button
                                        variant="studio-theme-blue-icon"
                                        @click="
                                          cardInfoPaymentInfo(
                                            subscription.paddle_subscription_id
                                          )
                                        "
                                      >
                                        <i class="fas fa-credit-card"></i>
                                      </b-button>
                                      <span class="blue"> Card Info </span>
                                    </div>
                                    <div
                                      v-tooltip="'Update card information'"
                                      class="button-with-text"
                                      @click="
                                        openPaddleWidget(
                                          subscription.update_url
                                        )
                                      "
                                    >
                                      <b-button
                                        variant="studio-theme-blue-icon"
                                      >
                                        <i class="icon-ZtoA"></i>
                                      </b-button>
                                      <span class="blue"> Update </span>
                                    </div>
                                    <div
                                      v-if="isActiveUserSuperAdmin()"
                                      v-tooltip="'Cancel subscription'"
                                      class="button-with-text"
                                      @click="cancelSubscription(subscription)"
                                    >
                                      <b-button
                                        variant="studio-theme-red-icon"
                                        class="ml-2"
                                      >
                                        <i class="icon-delete-cs"></i>
                                      </b-button>
                                      <span class="red ml-2"> Cancel </span>
                                    </div>
                                  </template>
                                  <template v-else> --- </template>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </template>
              <!-- payment history Section start here -->
              <div class="col-lg-12 top_heading">
                <h2>Invoices</h2>
              </div>

              <div class="col-lg-12">
                <div class="row subs-add-table">
                  <div class="col-lg-12">
                    <table>
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Plan</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th class="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <template v-if="isTransactionHistoryLoaded">
                          <tr class="spacer"> &nbsp;</tr>
                          <tr class="border">
                            <td colspan="6">
                              <clip-loader
                                :size="'14px'"
                                :color="'#6173ff'"
                              ></clip-loader>
                            </td>
                          </tr>
                        </template>
                        <template
                          v-if="
                            getPaddleTransactionsHistory.length === 0 &&
                            !isTransactionHistoryLoaded
                          "
                        >
                          <tr class="spacer"> &nbsp;</tr>
                          <tr class="border mt-3">
                            <td colspan="6" class="make-text-center"
                              >You have no invoices
                            </td>
                          </tr>
                        </template>
                        <template
                          v-for="(
                            transaction, index
                          ) in getPaddleTransactionsHistory"
                          v-else
                          :key="index"
                        >
                          <tr class="spacer"> &nbsp;</tr>
                          <tr class="border">
                            <td>{{ transaction.order_id }}</td>
                            <td>{{ transaction.plan_name }}</td>

                            <td>
                              <strong class="price-blue"
                                >{{
                                  findSymbolFromCurrencyCode(
                                    transaction.currency
                                  )
                                }}{{ transaction.amount }}</strong
                              >
                            </td>

                            <td>{{ getShortDate(transaction.created_at) }}</td>
                            <td>
                              <div class="td-actions-button">
                                <div
                                  v-tooltip="'View receipt'"
                                  class="button-with-text"
                                  @click="
                                    redirectReceiptURL(transaction.receipt_url)
                                  "
                                >
                                  <b-button
                                    variant="studio-theme-grey-icon"
                                    class="ml-2"
                                  >
                                    <i class="icon-download-cs"></i>
                                  </b-button>
                                  <span> Receipt </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <template
                v-if="getPaddleTransactionsHistory.length > 0 && page !== null"
              >
                <a
                  style="
                    margin-left: 15px;
                    margin-right: 12px;
                    margin-bottom: 10px;
                  "
                  class="btn btn-studio-theme-grey-space w-100 justify_center"
                  href="javascript:"
                  @click="fetchTransactionHistory"
                >
                  Load More Transactions?
                  <clip-loader
                    v-if="fetchingTransactions"
                    :size="'12px'"
                    :color="'#fff'"
                  ></clip-loader>
                </a>
              </template>
            </div>
          </div>

          <div class="plan_bar_section billing-right-section">
            <div
              v-if="isPastDue() && getPaymentFailedDetails.length > 0"
              class="subscription-plan-limits"
            >
              <h2>Payment Failures</h2>

              <div class="subscription-plan-widget">
                <template
                  v-for="transaction in getPaymentFailedDetails"
                  :key="transaction._id"
                >
                  <div class="subscription-plan-widget__box">
                    <p
                      >Failed to charge <b>${{ transaction.amount }}</b> on
                      <b>{{ getPaymentDate(transaction.event_time) }}</b></p
                    >
                    <p v-if="transaction.next_retry_date"
                      >Next attempt on
                      <b>{{
                        getPaymentDate(transaction.next_retry_date)
                      }}</b></p
                    >
                    <p v-else>Account set for <b>Cancellation</b></p>
                  </div>
                </template>
              </div>
            </div>
            <div v-if="isPaymentInformationAvailable" class="payment_info mb-4">
              <div class="row">
                <div class="col-lg-12">
                  <h2>Payment Information</h2>
                </div>
              </div>
              <div class="payment-information">
                <div class="payment-information-card">
                  <template
                    v-if="getPaymentInformation.payment_method === 'card'"
                  >
                    <template v-if="getPaymentInformation.card_type === 'visa'">
                      <img src="@assets/img/settings/cards/visa.svg" alt="" />
                    </template>
                    <template v-else>
                      <img
                        src="@assets/img/settings/cards/mastercard.svg"
                        alt=""
                      />
                    </template>
                  </template>
                  <template
                    v-else-if="
                      getPaymentInformation.payment_method === 'paypal'
                    "
                  >
                    <img src="@assets/img/settings/cards/paypal.svg" alt="" />
                  </template>
                  <template
                    v-else-if="
                      getPaymentInformation.payment_method === 'apple-pay'
                    "
                  >
                    <img
                      src="@assets/img/settings/cards/apple-pay.svg"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      src="@assets/img/settings/cards/mastercard.svg"
                      alt=""
                    />
                  </template>
                </div>
                <div class="payment-information-card-metadata">
                  <p>
                    <span>Last 4 digits:</span>
                    <b>{{ getPaymentInformation.last_four_digits }}</b>
                  </p>
                  <p>
                    <span>Expiry date:</span>
                    <b>{{ getPaymentInformation.expiry_date }}</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="subscription-plan-limits">
              <h2
                ><i
                  :class="
                    checkIfActiveWorkspaceHasLimitExceeded()
                      ? 'fas fa-exclamation-triangle limit_issue_billing'
                      : ''
                  "
                ></i>
                Limits</h2
              >

              <div class="subscription-plan-widget">
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getActiveWorkspaceOverUsedStatus().workspaces,
                    }"
                    >Workspaces</p
                  >
                  <p class="opacity-60">
                    {{ getUsedLimits('workspaces') }} of
                    {{ getSubscriptionLimits('workspaces') }}
                  </p>
                </div>
                <div class="subscription-widget-stats mb-3">
                  <b-progress
                    :max="getSubscriptionLimits('workspaces')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('workspaces')"
                    ></b-progress-bar>
                  </b-progress>
                </div>
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getActiveWorkspaceOverUsedStatus().profiles,
                    }"
                    >Social Accounts</p
                  >
                  <p class="opacity-60">
                    {{ getUsedLimits('profiles') }} of
                    <template v-if="getSubscriptionLimits('profiles') >= 3000">
                      &#8734;
                    </template>
                    <template v-else>
                      {{ getSubscriptionLimits('profiles') }}
                    </template>
                  </p>
                </div>
                <div class="subscription-widget-stats mb-3">
                  <b-progress
                    :max="getSubscriptionLimits('profiles')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('profiles')"
                    ></b-progress-bar>
                  </b-progress>
                </div>
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getActiveWorkspaceOverUsedStatus().members,
                    }"
                    >Team Members</p
                  >
                  <p class="opacity-60">
                    {{ getUsedLimits('members') }} of
                    {{ getSubscriptionLimits('members') }}
                  </p>
                </div>
                <div class="subscription-widget-stats mb-3">
                  <b-progress
                    :max="getSubscriptionLimits('members')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('members')"
                    ></b-progress-bar>
                  </b-progress>
                </div>
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getActiveWorkspaceOverUsedStatus().blogs,
                    }"
                    >Blogs</p
                  >
                  <p class="opacity-60">
                    {{ getUsedLimits('blogs') }} of
                    {{ getSubscriptionLimits('blogs') }}
                  </p>
                </div>
                <div class="subscription-widget-stats mb-3">
                  <b-progress
                    :max="getSubscriptionLimits('blogs')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('blogs')"
                    ></b-progress-bar>
                  </b-progress>
                </div>
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getActiveWorkspaceOverUsedStatus().automations,
                    }"
                    >Automation Campaigns</p
                  >
                  <p class="opacity-60">
                    {{ getUsedLimits('automations') }} of
                    <template
                      v-if="getSubscriptionLimits('automations') >= 1000"
                    >
                      &#8734;
                    </template>
                    <template v-else>
                      {{ getSubscriptionLimits('automations') }}
                    </template>
                  </p>
                </div>
                <div class="subscription-widget-stats mb-3">
                  <b-progress
                    :max="getSubscriptionLimits('automations')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('automations')"
                    ></b-progress-bar>
                  </b-progress>
                </div>
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getUsedLimits('media_storage') >
                        getSubscriptionLimits('media_storage'),
                    }"
                    >Media Storage</p
                  >
                  <p
                    class="opacity-60"
                    :class="{
                      limit_exceeded_for:
                        getUsedLimits('media_storage') >
                        getSubscriptionLimits('media_storage'),
                    }"
                  >
                    {{ bytesToSize(getUsedLimits('media_storage')) }} of
                    {{
                      bytesToSize(getSubscriptionLimits('media_storage')) || 0
                    }}
                  </p>
                </div>
                <div class="subscription-widget-stats mb-3">
                  <b-progress
                    :max="getSubscriptionLimits('media_storage')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('media_storage')"
                    ></b-progress-bar>
                  </b-progress>
                </div>

                <!-- Caption Generation Credits-->
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getUsedLimits('caption_generation_credit') >=
                        getSubscriptionLimits('caption_generation_credit'),
                    }"
                    >AI Text Credits (words)</p
                  >
                  <p
                    class="opacity-60"
                    :class="{
                      limit_exceeded_for:
                        getUsedLimits('caption_generation_credit') >
                        getSubscriptionLimits('caption_generation_credit'),
                    }"
                  >
                    {{
                      formatNumber(getUsedLimits('caption_generation_credit'))
                    }}
                    of
                    {{
                      formatNumber(
                        getSubscriptionLimits('caption_generation_credit')
                      )
                    }}
                  </p>
                </div>
                <div
                  class="subscription-widget-stats"
                  :class="{ 'mb-3': !isTrialPlan() }"
                >
                  <b-progress
                    :max="getSubscriptionLimits('caption_generation_credit')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('caption_generation_credit')"
                    ></b-progress-bar>
                  </b-progress>
                </div>
                <!-- Image Generation Credits-->
                <div class="subscription-widget-information">
                  <p
                    class="flex-grow-1 opacity-70"
                    :class="{
                      limit_exceeded_for:
                        getUsedLimits('image_generation_credit') >=
                        getSubscriptionLimits('image_generation_credit'),
                    }"
                    >AI Image Credits</p
                  >
                  <p
                    class="opacity-60"
                    :class="{
                      limit_exceeded_for:
                        getUsedLimits('image_generation_credit') >
                        getSubscriptionLimits('image_generation_credit'),
                    }"
                  >
                    {{ formatNumber(getUsedLimits('image_generation_credit')) }}
                    of
                    {{
                      formatNumber(
                        getSubscriptionLimits('image_generation_credit')
                      )
                    }}
                  </p>
                </div>
                <div
                  class="subscription-widget-stats"
                  :class="{ 'mb-3': !isTrialPlan() }"
                >
                  <b-progress
                    :max="getSubscriptionLimits('image_generation_credit')"
                    style="height: 7px"
                  >
                    <b-progress-bar
                      :variant="'studio-theme'"
                      :value="getUsedLimits('image_generation_credit')"
                    ></b-progress-bar>
                  </b-progress>
                </div>
              </div>
              <template v-if="!isTrialPlan()">
                <div
                  class="subscription-plan-increase-limits"
                  @click="$bvModal.show('increase-limits-dialog')"
                >
                  <b-button variant="studio-theme-space"
                    >Increase Limits</b-button
                  >
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'
import getSymbolFromCurrency from 'currency-symbol-map'
import { EventBus } from '@common/lib/event-bus'
import { swalAttributes } from '@common/constants/common-attributes'
import useDateFormat from '@common/composables/useDateFormat'
import usePermissions from '@common/composables/usePermissions'
import useWorkspace from '@common/composables/useWorkspace'
import WhiteLabelUpgradeModal from '@modules/setting/components/white-label/WhiteLabelUpgradeModal.vue'
import CstBanner from '@ui/CstBanner.vue'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
const CancelPlanDialog = () => import('./dialogs/CancelPlanDialog')
const CancelRecurringPlanDialog = () =>
  import('./dialogs/CancelRecurringPlanDialog')
export default {
  name: 'EnrolledPlanView',
  components: {
    CstBanner,
    WhiteLabelUpgradeModal,
    CancelPlanDialog,
    CancelRecurringPlanDialog,
  },
  setup() {
    const { momentWrapper } = useDateFormat()
    const { hasPermission, isPlanUpgradeable } = usePermissions()
    const { isActiveUserSuperAdmin, getSubscriptionLimits, getUsedLimits } =
      useWorkspace()
    const { isWhiteLabelUnlocked } = useWhiteLabel()
    const { featureFlag } = useComposerHelper()

    return {
      isWhiteLabelUnlocked,

      hasPermission,
      isActiveUserSuperAdmin,
      getSubscriptionLimits,
      getUsedLimits,
      isPlanUpgradeable,
      momentWrapper,
      featureFlag,
    }
  },
  data() {
    return {
      page: 1,
      mainSubscription: {},
      allSubscriptions: [],
      billing_history: {
        subscription: {},
      },
      transactions: [],
      subscriptions: [],
      loaders: {
        fetch_user_subscriptions: true,
        retrieve_transaction_history: true,
        pause_subscription: false,
        resume_subscription: false,
      },
      fetchingTransactions: false,
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getPlan', 'getPaymentFailedDetails']),
    isPaymentInformationAvailable() {
      return this.mainSubscription.payment_information
    },
    isSubscriptionsLoader() {
      return this.loaders.fetch_user_subscriptions
    },
    isTransactionHistoryLoaded() {
      return this.loaders.retrieve_transaction_history
    },
    isPauseSubscriptionLoader() {
      return this.loaders.pause_subscription
    },
    isResumeSubscriptionLoader() {
      return this.loaders.resume_subscription
    },

    getPaymentInformation() {
      return this.mainSubscription.payment_information
    },

    getPaddleTransactionsHistory() {
      return this.transactions
    },

    superAdmin() {
      return this.getWorkspaceSuperAdminDetails()
    },
  },
  created() {
    this.fetchUserSubscriptions()
    this.fetchTransactionHistory()
    this.fetchPlan()
  },
  mounted() {
    if (!this.hasPermission('can_see_subscription')) {
      this.$router.push({ name: 'profile' })
    }

    EventBus.$on('reload-billing-history', () => {
      this.reloadEvents()
    })
  },
  beforeUnmount() {
    EventBus.$off('reload-billing-history', () => {})
  },
  methods: {
    cancelSubscription(subscription) {
      // for main subscription
      if (
        this.mainSubscription.paddle_subscription_id ===
        subscription.paddle_subscription_id
      ) {
        this.$bvModal.show('cancel-recurring-plan-dialog')
      } else {
        // for addons
        this.openPaddleWidget(subscription.cancel_url)
      }
    },
    findSymbolFromCurrencyCode(code) {
      let symbol = getSymbolFromCurrency(code)
      if (symbol === '$' && code !== 'USD') {
        symbol = code + ' ' + symbol
      }
      return symbol
    },
    isPastDue() {
      return !!(this.superAdmin && this.superAdmin.state === 'past_due')
    },
    getPaymentDate(date) {
      return this.momentWrapper(date).formatDateTime()
    },
    getNextPaymentDate(date) {
      return this.momentWrapper(date).formatDateTime()
    },
    getShortDate(date) {
      if (!date) return ''
      return this.momentWrapper(date).formatDateTime()
    },
    redirectReceiptURL(url) {
      window.open(url, '_blank')
    },
    viewAddonsLimits(subscription) {
      const subscriptionLimits = JSON.parse(subscription.passthrough)
      if (subscription.modifiers && subscription.modifiers.length > 0) {
        subscription.modifiers.forEach((modifier) => {
          const modifierLimits = modifier.passthrough
          for (const key in modifierLimits) {
            if (
              modifierLimits[key] &&
              modifierLimits[key].quantity &&
              modifierLimits[key].price &&
              subscriptionLimits[key]
            ) {
              subscriptionLimits[key].quantity += modifierLimits[key].quantity
              subscriptionLimits[key].price = modifierLimits[key].price // new price will be the price of the addons
            } else if (!subscriptionLimits[key]) {
              subscriptionLimits[key] = modifierLimits[key]
            }
          }
        })
      }
      EventBus.$emit('show-addons-limit', {
        subscriptionLimits,
        type: subscription.billed_as,
      })
    },
    openPaddleWidget(url) {
      const self = this
      // eslint-disable-next-line no-undef
      Paddle.Checkout.open({
        override: url,
        successCallback: function (data) {
          console.log(data)
          self.fetchUserSubscriptions()
          self.fetchTransactionHistory()
          self.fetchPlan()
          self.$bvModal.hide('increase-limits-dialog')
        },
      })
    },
    async fetchUserSubscriptions() {
      this.loaders.fetch_user_subscriptions = true
      await proxy
        .get(
          `${billingBaseUrl}subscriptions?workspace_id=${this.$store.getters.getActiveWorkspace._id}`
        )
        .then((res) => {
          console.log('Fetch User Subscriptions', res.data)
          if (res.data.status) {
            this.mainSubscription = Object.assign(
              res.data.mainSubscription ?? {},
              res.data.mainSubscription?.paddle_details ?? {}
            )
            this.allSubscriptions = res.data.allSubscriptions
            console.log('mainSubscription', this.mainSubscription)
          }
        })
        .catch((err) => {
          console.error('Fetch User Subscriptions', err)
        })
      this.loaders.fetch_user_subscriptions = false
    },
    async fetchTransactionHistory() {
      if (!this.fetchingTransactions) {
        this.fetchingTransactions = true
        await proxy
          .get(
            `${billingBaseUrl}subscriptions/invoices?page=${
              this.page || 1
            }&workspace_id=${this.$store.getters.getActiveWorkspace._id}`
          )
          .then((res) => {
            if (res.data) {
              const resp = res.data
              if (resp.status && resp.invoices) {
                this.transactions = [...this.transactions, ...resp.invoices]
                this.page = this.page + 1
                if (resp.isLastPage) this.page = null
              }
            }
            this.fetchingTransactions = false
          })
          .catch((err) => {
            this.fetchingTransactions = false
            console.error('Fetch transaction History', err)
          })
      }
      this.loaders.retrieve_transaction_history = false
    },
    async pauseSubscriptionAction() {
      const res = await this.$bvModal
        .msgBoxConfirm('Are you sure you want to pause your subscription', {
          title: 'Pause Subscription',
          ...swalAttributes(),
        })
        .then((value) => {
          return !!value
        })
        .catch((err) => {
          // An error occurred
          console.error('pauseSubscriptionAction', err)
          return false
        })
      console.log('pause subscription response ->', res)

      if (res) {
        this.loaders.pause_subscription = true
        await this.$store.dispatch('pauseSubscriptionById')
        this.loaders.pause_subscription = false
        this.reloadEvents()
      }
    },
    async resumeSubscriptionAction() {
      this.loaders.resume_subscription = true
      await this.$store.dispatch('resumePaddleSubscription')
      this.loaders.resume_subscription = false
      this.reloadEvents()
    },
    reloadEvents() {
      this.transactions = []
      this.fetchUserSubscriptions()
      this.fetchTransactionHistory()
    },
    async cardInfoPaymentInfo(subscriptionId) {
      await proxy
        .get(
          `${billingBaseUrl}subscription/paymentInfo?subscription_id=${subscriptionId}&workspace_id=${this.$store.getters.getActiveWorkspace._id}`
        )
        .then((res) => {
          if (res.data) {
            const response = res.data
            if (response.status && response.paymentInfo) {
              EventBus.$emit('Card-info-dialog', response.paymentInfo)
            }
          }
        })
        .catch((err) => {
          console.error('Card Payment Info err:', err)
        })
    },
  },
}
</script>

<style lang="less" scoped>
@import (reference) '~@src/assets/less/basic/_colors.less';
/* variables */
@light-black-color: #3a4557;
@light-grey-color: #eceff3;
@extra-light-pink: #ffe6ed;
@medium-brown: #e02f5f;
@medium-grey: #707070;
@medium-blue: #2d3f8e;
@medium-green: #ffbb02;

@media only screen and (min-width: 2000px) {
  .current-plan-box-image {
    content: '';
    margin-top: -17px;
    right: 0 !important;
    left: 38.9% !important;
    height: 128px !important;
    width: 177px !important;
    position: absolute;
  }

  .subscription-next-payment-icon {
    margin-left: 31rem !important;
  }
}

@media only screen and (max-width: 1680px) {
  .current-plan-box-image {
    content: '';
    margin-top: -17px;
    right: 0 !important;
    left: 36.3% !important;
    height: 128px !important;
    width: 177px !important;
    position: absolute;
  }

  .subscription-next-payment-icon {
    margin-left: 23rem !important;
  }

  .payment-information-card {
    img {
      width: 126px !important;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .subscription-next-payment-icon {
    margin-left: 20rem !important;
  }
}
@media (max-width: 1600px) and (min-width: 1360px) {
  .active-subscription-box,
  .only-single-box {
    max-width: 80% !important;
    width: 80% !important;
  }
}
@media only screen and (max-width: 1500px) {
  .billing-left-section {
    max-width: 70% !important;
    flex: 0 0 70% !important;
  }

  .billing-right-section {
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }

  .current-plan-box-image {
    left: 34.3% !important;
  }

  .subscription-next-payment-icon {
    margin-left: 17rem !important;
  }

  .only-single-box {
    background-size: 150px !important;
  }
  .active-subscription-box,
  .only-single-box {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1480px) {
  .payment-information-card {
    img {
      width: 119px !important;
      height: 78px !important;
    }
  }

  .active-subscription-boxes {
    flex-direction: column;
  }

  .active-subscription-box,
  .only-single-box {
    max-width: 65% !important;
    width: 65% !important;
  }

  .active-subscription-boxes {
    display: flex;
    flex-direction: column !important;
  }

  .current-plan-box {
    margin-bottom: 1.5rem;
  }

  .active-subscription-box:last-child {
    margin-left: 0 !important;
  }

  .current-plan-box-image {
    left: 43% !important;
  }

  .billing-left-section,
  .billing-right-section {
    font-size: 12px;
  }

  .subscription-next-payment-icon {
    margin-left: 22rem !important;
  }
}

@media only screen and (max-width: 1350px) {
  .billing-left-section {
    max-width: 65% !important;
    flex: 0 0 65% !important;
  }

  .billing-right-section {
    flex: 0 0 35% !important;
    max-width: 35% !important;
  }

  .current-plan-box-image {
    left: 38.5% !important;
  }

  .subscription-next-payment-icon {
    margin-left: 19rem !important;
  }
}

@media only screen and (max-width: 1280px) {
  .billing-left-section {
    max-width: 60% !important;
    flex: 0 0 60% !important;
  }

  .billing-right-section {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }

  .active-subscription-box,
  .only-single-box {
    max-width: 100% !important;
    width: 100% !important;
  }

  .current-plan-box-image {
    left: 51.8% !important;
  }

  .subscription-next-payment-icon {
    margin-left: 28rem !important;
  }
}

/* billing plan page start here */
.billing-left-section {
  max-width: 75%;
  width: 75%;
}

.billing-right-section {
  max-width: 28%;
  width: 28%;
}

.billing-section-row {
  display: flex;
  flex-direction: row;
}

.billing_section {
  .top_heading {
    h2,
    h1 {
      line-height: 5rem;
      font-size: 1.5em;
      font-weight: 500;
      color: @light-black-color;
    }
  }

  .plan_bar_section {
    padding: 1.8em;

    .plan_bars {
      margin-bottom: 15px;
      line-height: 2em;
      padding: 20px;

      .bar_item {
        .limit_add {
          background: #6173ff;
        }

        label {
          display: block;
          text-align: left;
          font-weight: normal;
          font-size: 0.875rem;
          color: @light-black-color;
        }
      }
    }

    .payment_info {
      padding: 1.5rem 1.5rem;
      border-radius: 15px;
      background-color: @light-grey-color;

      h2 {
        color: @light-black-color;
        font-weight: 500;
        font-size: 1.4em;
        padding-bottom: 1.2rem;
      }

      .payment-information {
        align-items: flex-start;
        display: flex;
        flex-direction: row;

        img {
          height: 101px;
          width: 142px;
          margin-left: -1rem;
        }
      }

      .payment-information-card-metadata {
        padding-top: 1rem;

        p {
          line-height: 2em;

          span {
            color: @cs-light-text-color;
            padding-right: 0.5rem;
          }
        }
      }
    }
  }

  .subscription-plan-limits {
    padding: 1.5rem 1.5rem;
    border: 2px solid @cs-border-light-grey-color;
    border-radius: 15px;
    margin-bottom: 1.4rem;

    h2 {
      color: @light-black-color;
      font-weight: 500;
      font-size: 1.4em;
    }

    .subscription-plan-widget {
      padding: 1rem 0;
      border-radius: 4px;
      display: flex;
      flex-direction: column;

      &__box {
        border: 1px solid rgba(112, 112, 112, 0.16);
        padding: 1.2rem 0;

        border-left: transparent;
        border-right: transparent;
        border-bottom: transparent;
        p {
          font-size: 1em;
        }

        &:first-child {
          border-top: none;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      .subscription-widget-information {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;

        p {
          color: @cs-text-color;
          font-weight: 400;
          font-size: 1em;
          padding-bottom: 0.8rem;
        }
      }

      .subscription-widget-stats {
        display: flex;
        flex-direction: column;

        p {
          font-weight: 500;
          padding-bottom: 0.5rem;
        }
      }
    }

    .subscription-plan-increase-limits {
      display: flex;
      flex-grow: 1;
      justify-content: center;

      button {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .billing-problem-notification {
    background-color: @extra-light-pink;
    padding: 1.5rem 1rem;
    border-radius: 15px;
    margin: 0;

    h2 {
      font-size: 1.4em;
      font-weight: 500;
      line-height: 1.5rem;
    }

    p {
      padding: 1rem 1rem 1rem 0;
      font-size: 1em;
      max-width: 550px;
    }

    .upgrade-link {
      color: @medium-brown;
      font-size: 0.9em;
      font-weight: 500;

      i {
        transform: rotateY(30deg);
      }
    }

    .cat-vector {
      position: absolute;
      top: -85px;
      right: 1rem;
      height: 190px;
    }
  }

  .subs-add-table {
    margin-bottom: 1em;

    table {
      width: 100%;

      thead {
        background-color: @light-grey-color;
        line-height: 2em;
        font-size: 0.9em;

        th:first-child {
          border-radius: 5px 0 0 5px;
          padding: 15px 5px 15px 20px;
        }

        th:last-child {
          border-radius: 0 5px 5px 0;
          padding: 0.625rem;
        }

        tr {
          font-size: 1.1em;
          font-weight: 500;
        }
      }

      tbody {
        tr:first-child td:first-child {
          border-top-left-radius: 0.625rem;
        }

        tr:first-child td:last-child {
          border-top-right-radius: 0.625rem;
        }

        tr:last-child td:first-child {
          border-bottom-left-radius: 0.625rem;
        }

        tr:last-child td:last-child {
          border-bottom-right-radius: 0.625rem;
        }

        tr.border {
          border: 1px solid @medium-grey;
        }

        td:first-child {
          border-radius: 5px 0 0 5px;
          padding: 20px 5px 20px 20px;
        }

        td:last-child {
          border-radius: 0 5px 5px 0;
          padding: 0.625rem;
        }

        tr {
          font-size: 1em;

          .price-blue {
            color: @medium-blue;
          }

          .status-pending,
          .status-paused {
            color: @medium-green;
            text-transform: capitalize;
          }

          .status-active {
            text-transform: capitalize;
            color: @cs-green-color;
            font-weight: 500;
          }

          .status-due {
            text-transform: capitalize;
            color: @cs-button-red-color;
            font-weight: 500;
          }

          .recepient {
            img {
              width: 55px;
              border-radius: 5px;
              margin-right: 3px;
            }
          }

          .action-icons {
            p {
              padding: 9px;
              font-size: 0.9em;
            }
          }
        }
      }
    }
  }

  .grey-border-right {
    border-right: 1px solid @cs-border-light-grey-color;
  }

  .active-subscription-boxes {
    display: flex;
    flex-direction: row;
    /*height:280px;*/
    width: 100%;
    margin: 0 1rem 0;

    .active-subscription-box {
      /*height:280px;*/
      width: 100%;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 1rem 1.5rem;
    }

    .active-subscription-heading {
      font-weight: 400;
      font-size: 1.3em;
      opacity: 0.8;
      color: @cs-text-color;
      padding: 1rem 0;
      z-index: 1000;
    }

    .active-subscription-price {
      font-weight: 500;
      font-size: 1.8em;
      z-index: 1000;
    }

    .width-70 {
      width: 70%;
    }

    .active-subscription-plan {
      font-weight: 400;
      padding: 1rem 0 2rem;
    }

    .active-subscription-buttons {
      display: flex;
      flex-direction: row;
      padding-bottom: 1rem;

      button {
        padding: 0.65rem 1.5rem;
      }
    }

    .active-subscription-note {
      color: @cs-text-color;
    }

    .active-subscription-box:first-child {
      margin-right: 0.75rem;
      background: @cs-background-light-purple;
    }

    .active-subscription-box:last-child {
      margin-left: 0.75rem;
    }

    .only-single-box {
      width: 55%;
      margin-right: 0.75rem;
      margin-left: 0 !important;
      background-image: url('../../../../assets/img/modules/billing/current-sp-art.png') !important;
      background-repeat: no-repeat !important;
      background-position-x: right !important;
      background-size: 200px;
      /* .current-subscription-plan {
                 left: 29.29% !important;
                 margin-top: -17px;
             }*/
    }
  }

  /*  .current-subscription-plan {
          position: absolute;
          margin-top: -15px;
          left: 29.2%;
      }*/

  .subscription-next-payment-icon {
    position: absolute;
    margin-top: 1rem;
    margin-left: 28rem;
  }

  .current-plan-box-image {
    content: '';
    margin-top: -17px;
    /*right: 54.2%;*/
    left: 33.4%;
    height: 172px;

    width: 236px;
    position: absolute;
  }
}
</style>
