<script setup>
// eslint disabled until we update linting rules
/* eslint-disable */
import { inject, ref } from 'vue'
import { paddle_ids as paddleIDs } from '@common/constants/pricing.js'
import useWorkspace from '@common/composables/useWorkspace'
import usePlatform from '@common/composables/usePlatform'
import usePermissions from '@common/composables/usePermissions'

defineProps({
  showCloseButton: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['close'])

const root = inject('root')
const { $bvModal } = root
const planType = ref('')
const { showToast } = usePlatform()
const { getSuperAdmin } = useWorkspace()
const { hasPermission } = usePermissions()

const handlePurchaseNow = () => {
  if (planType.value === '' || !hasPermission('can_see_subscription')) return
  const passThrough = {
    user_id: getSuperAdmin()?._id,
    user_email: getSuperAdmin()?.email,
  }

  Paddle.Checkout.open({
    email: getSuperAdmin()?.email,
    title: 'White Label',
    passthrough: JSON.stringify(passThrough),
    product:
      planType.value === 'monthly'
        ? paddleIDs[process.env.VUE_APP_ENVIRONMENT].white_label_monthly
        : paddleIDs[process.env.VUE_APP_ENVIRONMENT].white_label_annual,
    successCallback: function () {
      showToast('Thanks for your purchase.', 'success')
      setTimeout(async () => {
        // reload the page to update the limits
        window.location.reload()
      }, 2000)
    },
  })
}
</script>

<template>
  <div class="w-full p-6 bg-white rounded-xl">
    <div class="flex w-full items-center justify-between mb-4">
      <p class="text-2xl font-medium leading-none">White Label</p>
      <div
        v-if="showCloseButton"
        v-tooltip="'Close'"
        class="bg-gray-200 cursor-pointer flex items-center justify-center p-2.5 rounded-lg"
        @click="$emit('close')"
      >
        <img
          alt="close icon"
          class="h-3 w-3"
          src="@assets/img/icons/close_cross.svg"
        />
      </div>
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-5 gap-6 2xl:gap-7"
    >
      <div class="w-full 2xl:col-span-2">
        <p class="mb-6"
          >Impress your clients with a fully branded platform using
          ContentStudio’s white-label feature. Showcase your brand, build trust,
          and provide a seamless experience – giving clients the feel of an
          in-house solution.</p
        >
        <ul class="mb-8 space-y-2 p-3 rounded-lg bg-[#F8F8F8]">
          <li class="flex items-center">
            <img
              alt=""
              class="mr-2 h-4 w-4"
              src="@assets/img/icons/tick_grey.svg"
            />
            <p class="text-sm"
              >Use your logo, domain, and emails for a professional look..</p
            >
          </li>
          <li class="flex items-center">
            <img
              alt=""
              class="mr-2 h-4 w-4"
              src="@assets/img/icons/tick_grey.svg"
            />
            <p class="text-sm"
              >Create a custom, in-house experience for the team and clients.</p
            >
          </li>
          <li class="flex items-center">
            <img
              alt=""
              class="mr-2 h-4 w-4"
              src="@assets/img/icons/tick_grey.svg"
            />
            <p class="text-sm">Build trust with a fully branded interface.</p>
          </li>
        </ul>
        <div class="mb-8 flex space-x-4">
          <div
            :class="{ '!border-blue-500 bg-[#F8FBFF]': planType === 'monthly' }"
            class="border flex flex-1 py-3 px-4 rounded-lg cursor-pointer hover:!border-blue-500"
            @click="planType = 'monthly'"
          >
            <div class="w-full">
              <p class="mb-1 font-medium">Monthly</p>
              <p class="text-2xl font-medium"
                >$50<span class="text-sm font-normal">/month</span></p
              >
            </div>
            <input
              v-model="planType"
              class="scale-125 self-baseline"
              name="competitor_analytic_price"
              type="radio"
              value="monthly"
            />
          </div>
          <div
            :class="{ '!border-blue-500 bg-[#F8FBFF]': planType === 'annual' }"
            class="border flex flex-1 py-3 px-4 rounded-lg cursor-pointer hover:!border-blue-500"
            @click="planType = 'annual'"
          >
            <div class="w-full">
              <p class="mb-1 font-medium">Yearly</p>
              <p class="text-2xl font-medium"
                >$500<span class="text-sm font-normal">/year</span></p
              >
            </div>
            <input
              v-model="planType"
              class="scale-125 self-baseline"
              name="competitor_analytic_price"
              type="radio"
              value="annual"
            />
          </div>
        </div>
        <div
          class="flex"
        >
          <div
            v-tooltip.top="{
              content: !hasPermission('can_see_subscription')
                ? `You don't have access to this feature. Please contact your Super Admin at ${
                    getSuperAdmin()?.email
                  } for assistance`
                : planType === ''
                ? `Kindly select a subscription plan prior to proceeding: $25/month OR $250/year`
                : '',
              delay: 0,
            }"
            :class="[
              !hasPermission('can_see_subscription') || planType === ''
                ? 'bg-opacity-50 cursor-not-allowed select-none'
                : 'hover:bg-blue-600',
            ]"
            class="border !border-bg-blue-500 rounded-lg bg-blue-500 px-6 py-2 font-semibold text-white transition duration-300 cursor-pointer ml-auto"
            @click="handlePurchaseNow"
          >
            Purchase Now
          </div>
        </div>
      </div>

      <div class="w-full lg:col-span-1 2xl:col-span-3 aspect-w-16 aspect-h-9">
        <iframe
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          class="rounded-xl w-full h-full"
          frameBorder="0"
          src="https://www.youtube.com/embed/MDksxxwcTsw?showinfo=0&rel=0"
          title="How to Beat the Competition with Instagram Competitor Analytics"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>
