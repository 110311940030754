<script setup>
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'
import CstButton from '@ui/Button/CstButton.vue'
import useDashboard from "@src/modules/dashboard/composables/useDashboard";
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
const { isWhiteLabelDomain } = useWhiteLabelApplication()
const {navigationNext, navigationPrev} = useDashboard()

const redirectToPlayStore = () => {
  window.open(
    'https://play.google.com/store/apps/details?id=com.muneeb.contentstudio',
    '_blank'
  )
}

const redirectToAppStore = () => {
  window.open(
    'https://apps.apple.com/us/app/contentstudio-smm-tool/id1439314125',
    '_blank'
  )
}

const redirectToChromeExtension = () => {
  window.open(
    'https://chrome.google.com/webstore/detail/contentstudio-social-medi/dmcddloohffhmjngiieikfifpfneadcc',
    '_blank'
  )
}
</script>

<template>
  <Carousel
    navigation-enabled
    pagination-color="#D2D5DF"
    pagination-active-color="#2973E5"
    :per-page="1"
    :navigation-next-label="navigationNext"
    :navigation-prev-label="navigationPrev"
    class="min-h-[14.375rem] h-full w-full dashboardCarousel__carousel"
  >
    <Slide>
      <div class="flex h-full w-full p-1">
        <div
          class="bg-no-repeat bg-cover bg-center w-full h-full"
          :style="{
            backgroundImage: `url(${require('@assets/img/dashboard/images/chrome.svg')})`,
          }"
        >
          <div class="flex flex-col gap-4 pt-8 pl-8">
            <p class="text-primary-cs text-lg font-bold leading-none"
              >Chrome Extension</p
            >
            <sub v-if="isWhiteLabelDomain" v-tooltip="'Enhance your workflow with our Chrome extension, designed to make your tasks easier and more efficient.'" class="text-gray-cs-900 text-sm line-clamp-3 w-[11rem]"
            >Enhance your workflow with our Chrome extension, designed to make your tasks easier and more efficient.</sub>
            <sub v-else class="text-gray-cs-900 text-sm line-clamp-3 w-[11rem]"
          >ContentStudio makes your work easy and efficient by its chrome extension.</sub>
            <CstButton
              variant="secondary"
              class="!px-3 !py-[0.438rem] !rounded w-max"
              @click="redirectToChromeExtension"
              ><p class="text-xs font-normal text-light-cs"
                >Install Now</p
              ></CstButton
            >
          </div>
        </div>
      </div>
    </Slide>
    <Slide>
      <div class="flex h-full w-full p-1">
        <div
            class="bg-right bg-no-repeat w-full h-full"
            :style="{
            backgroundImage: `url(${require('@assets/img/dashboard/images/mobile-app.png')})`,
          }"
        >
          <div class="flex flex-col pt-7 pl-8">
            <p class="text-primary-cs text-lg font-bold leading-none mb-3.5"
            >Mobile App</p
            >
            <sub v-if="isWhiteLabelDomain" class="text-gray-cs-900 text-sm line-clamp-3 w-[11rem] mb-5"
            >Our mobile app streamlines your work, helping you stay efficient and productive wherever you are.</sub
            >
            <sub v-else class="text-gray-cs-900 text-sm line-clamp-3 w-[11rem] mb-5"
            >ContentStudio makes your work easy and efficient by its mobile
              app.</sub
            >
            <img
                src="@assets/img/dashboard/icons/google-play.svg"
                :draggable="false"
                class="mb-1 w-[5.625rem] h-[1.688rem] cursor-pointer"
                alt=""
                @click="redirectToPlayStore"
            />
            <img
                src="@assets/img/dashboard/icons/apple-store.svg"
                :draggable="false"
                class="w-[5.625rem] h-[1.688rem] cursor-pointer"
                alt=""
                @click="redirectToAppStore"
            />
          </div>
        </div>
      </div>
    </Slide>
  </Carousel>
</template>
